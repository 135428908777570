import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

export const CTA = ({ text, url, small, color, className }) => (
  <Link to={url}>
    <StyledCTA
      small={small}
      color={color}
      className={className ? `cta ${className}` : 'cta'}
    >
      <span>{text}</span>
    </StyledCTA>
  </Link>
)

const rippleIn = keyframes`
  0% {
      width: 150%;
      height:0;
      padding-bottom: 150%;
      border-radius: 50%;
      opacity : 1;
    }
    100% {
        width: 0;
        height: 0;
        opacity : 0;
    }
`

const rippleOut = keyframes`
  0% {
      width: 0;
      height: 0;
      opacity : 0;
  }
  100% {
    width: 150%;
    height:0;
    padding-bottom: 150%;
    border-radius: 50%;
    opacity : 1;
  }
`

const StyledCTA = styled.button`
  position: relative;
  overflow: hidden;
  background: ${(props) =>
    props.color === 'white'
      ? props.theme.white
      : props.color === 'gold'
      ? props.theme.gold
      : props.theme.blue};
  color: ${(props) =>
    props.color === 'white' ? props.theme.blue : props.theme.white};
  font-weight: ${({ theme }) => theme.fontRegular};
  font-size: ${(props) => (props.small ? '1.5rem' : '2rem')};
  border-radius: 25px;
  padding: ${({ theme }) => `1.3rem ${theme.spacingS} ${theme.spacingXXS}`};
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  transform: translateZ(0);
  transition: all 0.25s ease;

  span {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 200%;
    background: ${(props) =>
      props.color === 'white'
        ? props.theme.blue
        : props.color === 'gold'
        ? props.theme.blue
        : props.theme.gold};
    border-radius: 50%;
    z-index: 0;
    transform: scale(0) translateX(-50%);
    transform-origin: left;
    transition: all 0.5s ease;
  }

  &:hover {
    color: ${({ theme }) => theme.white};

    &:before {
      transform: scale(1.5) translateX(-50%);
    }
  }

  &.to-white {
    &:before {
      background: ${({ theme }) => theme.white};
    }
    &:hover {
      color: ${({ theme }) => theme.blue};
    }
  }
`

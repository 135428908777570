import { ConsentGtag } from "./consentGtag"

export const handlePhoneClick = (url, type) => {

    ConsentGtag()
    if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
        window.gtag('event', 'chamadas', {
            tipo_chamada: type
        })
        window.open(url, '_self', 'noreferrer')
    }
}
import React from 'react'
import PhoneIcon from '../assets/images/phone.svg'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import { handlePhoneClick } from '../utils/handleClick'

export const FloatingCTA = () => {
  
  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            phone1
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledButton>

          <a onClick={() => handlePhoneClick(`tel: ${content.phone1.replace(/\s/g, '')}`, 'Móvel')}
          >
            <PhoneIcon />
            <div className='div-txt'>
              «Chamada para a rede móvel nacional»
            </div>
          </a>
        </StyledButton>
      )}
    />
  )
}

const StyledButton = styled.button`
cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.18);
  z-index: 111;

  overflow: hidden;
  transform: translateZ(0);
  transition: all 0.25s ease;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 200%;
    height: 200%;
    background:  ${({ theme }) => theme.blue};
    border-radius: 50%;
    z-index: 0;
    transform: scale(0) translateX(-50%);
    transform-origin: left;
    transition: all 0.5s ease;
  }
  &:hover {
    color: ${({ theme }) => theme.white};

    &:before {
      transform: scale(1.5) translateX(-50%);
    }
  }

  a{
    display: inline-flex;
    position: relative;
    z-index: 1;
    overflow: hidden;
    align-items: center;
    height: 5vh;
  
    :hover{

      svg{
        border-color: ${({ theme }) => theme.white};
        path {
          fill: ${({ theme }) => theme.white};
        }
      }
      .div-txt{
        color: ${({ theme }) => theme.white};
      }
    }
  }

  svg {
    width: 5vh;
    border-right: 1px solid ${({ theme }) => theme.blue};
    border-radius: 50%;
    padding: 1vh;
    path {
      fill: ${({ theme }) => theme.blue};
    }
  }

  .div-txt{
    line-height: 1;
    color: #053D7F;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.1vh;
    margin: auto;
    max-width: 14vh;
    padding-right: 1.1vh;
  }

 
  }
`

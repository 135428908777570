import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
        scroll-behavior: smooth;

        
    }

    body {
        font-family: ${({ theme }) => theme.fontFamily};

        &.scroll-lock {
            max-height: 100vh;
            overflow: hidden;
        }
        
    #___gatsby {
        max-width: 100vw;
        overflow: hidden;
      }    
    
      @media (max-width: 990px) {
        &:has(.open-menu) {
          .main {
            pointer-events: none;
            width: 100vw;
            overflow-y: hidden;
            margin: auto;
            overflow-x: hidden;
            z-index: -1;
          }
    
          .container-main-top {
            overflow: hidden;
          }
        }
    
        .main {
          position: relative;
          margin: auto;
        }
      }
    }

    main, footer {
        max-width: 100vw;
        overflow: hidden;
    }


body:has(.cky-consent-container.cky-hide):not(:has(.cky-modal-open)) {
  overflow-y: visible !important;
  width: 100% !important;
  height: 100% !important;
}

body {
  overflow-y: hidden !important;
  width: 100% !important;
  height: 100vh !important;
}

.cky-consent-container {
  width: 100vw !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
}

.cky-consent-container .cky-consent-bar {
  max-width: 440px !important;
  top: 50% !important;
  left: 50% !important;
  position: relative !important;
  transform: translate(-50%, -50%) !important;
}

.cky-notice-group .cky-notice-btn-wrapper .cky-btn-reject {
  display: none !important;
}

    a {
        color: inherit;
        text-decoration: none;
    }

    input, input:focus, input:active, textarea, textarea:focus, textarea:active {
        outline: none;
    }

    button {
        border: none;
        font-family: ${({ theme }) => theme.fontFamily};
    }

    .flex-center {
        display: flex;
        align-items: center;
    }

    .mini-title {
        font-weight: ${({ theme }) => theme.fontSemiBold};
        font-size: 2rem;
        line-height: 3.3rem;
        letter-spacing: .003rem;
        color: ${({ theme }) => theme.gold};
        text-transform: uppercase;
        text-align: center;
    }

    // Cores
    .blue {
        color: ${({ theme }) => theme.blue};
    }
    .gold {
        color: ${({ theme }) => theme.gold};
    }
    .white {
        color: ${({ theme }) => theme.white};
    }
    .black {
        color: ${({ theme }) => theme.black};
    }
    .bg-blue {
        background-color: ${({ theme }) => theme.blue};
    }
    .bg-gold {
        background-color: ${({ theme }) => theme.gold};
    }
    .bg-white {
        background-color: ${({ theme }) => theme.white};
    }

    // "Mobile only" and "Desktop only"
    .mbl {
        display: none;
    }

    .desktop {
        display: block;
    }

    @media screen and (max-width: 1600px) {
        html {
            font-size: 54%;
        }
    }

    @media screen and (max-width: 1200px) {
        html {
            font-size: 50%;
        }
    }

    @media screen and (max-width: 1000px) {
        .mbl {
        display: block;
        }

        .desktop {
        display: none !important;
        }
    }

    @media screen and (max-width: 600px) {
        html {
            font-size: 40%;
        }
    }
`

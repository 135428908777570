import React, { useState, useEffect } from 'react'
import { CTA } from './CTA'
import Logo from '../assets/images/logo.svg'
import MarkerIcon from '../assets/images/marker.svg'
import PhoneIcon from '../assets/images/phone.svg'
import ClockIcon from '../assets/images/clock.svg'
import FacebookIcon from '../assets/images/facebook.svg'
import InstagramIcon from '../assets/images/instagram.svg'
import YoutubeIcon from '../assets/images/youtube.svg'
import LinkedinIcon from '../assets/images/linkedin.svg'
import CloseIcon from '../assets/images/close.svg'
import { debounce } from '../utils/helpers'
import styled from 'styled-components'
import { Link, StaticQuery, graphql  } from 'gatsby'

export const Navbar = ({ isHomePage, location  }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [visible, setVisible] = useState(true)

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset
    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
      currentScrollPos < 10
    )
    setPrevScrollPos(currentScrollPos)
  }, 100)



  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('scroll-lock')
    } else {
      document.body.classList.remove('scroll-lock')
    }
    
  }, [isOpen])

  useEffect(() => {
    if (location.hash === '#marcacao') {
      setIsOpen(false)
    } 
  }, [location])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos, visible, handleScroll])

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            address
            googleMapsLink
            phone1
            phone2
            schedule
            cta
            ctaUrl
            homeCtaUrl
            navItems {
              label
              url
              children {
                label
                url
              }
            }
            facebookUrl
            instagramUrl
            youtubeUrl
            linkedinUrl
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledNav visible={visible}>
          <div className="nav__topbar bg-blue white ">
            <div className="nav__topbar bg-blue white flex-center">
              <div className="flex-center">
                <div className="topbar__item flex-center">
                  <Link to="/contactos">
                    <ClockIcon />
                  </Link>
                  <div >
                    <span>{content.schedule[0]} {content.schedule[1]}</span>
                  </div>
                </div>
              </div>
            </div>
           
          </div>

          <div className="nav__main flex-center">
            <div>
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="main__items flex-center">
              <CTA
                text={content.cta}
                url={isHomePage ? content.homeCtaUrl : content.ctaUrl}
                small
                className="desktop"
                color="gold"
              />
              {content.navItems.map((item) =>
                item.children ? (
                  <div
                    key={item.url}
                    href="#"
                    className="blue has-dropdown desktop"
                  >
                    {item.label}
                    <ul className="dropdown bg-blue white">
                      {item.children.map((child) => (
                        <Link key={child.url} to={child.url}>
                          <li>{child.label}</li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link key={item.url} to={item.url} className="blue desktop">
                    {item.label}
                  </Link>
                )
              )}

              <div className="social flex-center">
                <a href={content.facebookUrl} target="_blank" rel="noopener">
                  <FacebookIcon />
                </a>
                <a href={content.instagramUrl} target="_blank" rel="noopener">
                  <InstagramIcon />
                </a>
                <a
                  href={content.youtubeUrl}
                  className="youtube"
                  target="_blank"
                  rel="noopener"
                >
                  <YoutubeIcon />
                </a>
                <a
                  href={content.linkedinUrl}
                  className="linkedin"
                  target="_blank"
                  rel="noopener"
                >
                  <LinkedinIcon />
                </a>
              </div>

              <div onClick={() => setIsOpen(true)} className="hamburguer mbl">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>

          <div
            className={
              isOpen ? 'mobile-nav mbl bg-gold open' : 'mobile-nav mbl bg-gold'
            }
          >
            <div className="mobile-nav__top flex-center">
              <Logo className="mobile-logo" />
              <span>
                <CloseIcon
                  onClick={() => setIsOpen(false)}
                  className="close-icon"
                />
              </span>
            </div>

            <div className="mobile-nav__items">
              <CTA
                text={content.cta}
                url={isHomePage ? content.homeCtaUrl : content.ctaUrl}
                small
              />

              {content.navItems.map((item) =>
                item.children ? (
                  <div
                    key={item.url}
                    href="#"
                    className="nav-item has-dropdown white"
                  >
                    {item.label}
                    <ul className="white">
                      {item.children.map((child) => (
                        <Link key={child.url} to={child.url}>
                          <li>{child.label}</li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <Link key={item.url} to={item.url} className="nav-item white">
                    {item.label}
                  </Link>
                )
              )}
            </div>

            <div className="mobile-nav__social flex-center">

              <a href={content.facebookUrl} target="_blank" rel="noopener">
                <FacebookIcon />
              </a>
              <a href={content.instagramUrl} target="_blank" rel="noopener">
                <InstagramIcon />
              </a>
              <a href={content.youtubeUrl} target="_blank" rel="noopener">
                <YoutubeIcon />
              </a>
              <a href={content.linkedinUrl} target="_blank" rel="noopener">
                <LinkedinIcon />
              </a>
            </div>
          </div>
        </StyledNav>
      )}
    />
  )
}

const StyledNav = styled.nav`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 111;
  background: ${({ theme }) => theme.white};
  transform: ${(props) =>
    props.visible ? 'translateY(0)' : 'translateY(-15rem)'};
  transition: transform 0.25s ease;

  .nav__topbar {
    height: 4rem;
    padding: 0 ${({ theme }) => theme.spacingM};
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.03rem;
    font-weight: ${({ theme }) => theme.fontBold};
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      margin: auto;
    }

    .flex-center{
      height: 4rem;
    }
  }

  @media (max-width: 990px){
    .nav__topbar {
      padding: 0 ;
      height: 4rem;
    }
    .nav__main{
      padding: 0rem 1.5rem 0rem 1.5rem !important;
    }
  }

  @media (min-width: 991px){
    .mobile-text{
      display: none!important;
    }
  }

  .topbar__item {
    display: flex;
    align-items: center;
    svg {
      display: block;
      width: 2rem;
      margin-right: ${({ theme }) => theme.spacingXXXS};
    }

    &:nth-child(2) {
      margin-left: ${({ theme }) => theme.spacingXS};
    }
  }

  .nav__main {
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.spacingM};

    svg {
      width: 17rem;

      path,
      rect {
        fill: ${({ theme }) => theme.blue};
      }
    }
  }

  .main__items {
    a,
    .has-dropdown {
      display: flex;
      align-items: center;
      min-height: 8rem;
      font-size: 1.5rem;
      font-weight: ${({ theme }) => theme.fontBold};
      letter-spacing: 0.003rem;
      text-transform: uppercase;
      margin: 0 ${({ theme }) => theme.spacingS};
      cursor: pointer;
      transition: color 0.25s ease;

      &:hover {
        color: ${({ theme }) => theme.gold};
      }
    }

    .has-dropdown {
      position: relative;

      &:hover .dropdown {
        transform: scaleY(1) translateX(-50%);
      }
    }

    .dropdown {
      list-style-type: none;
      position: absolute;
      top: 8.2rem;
      left: 50%;
      padding: ${({ theme }) => theme.spacingXS} 0;
      transform: scaleY(0) translateX(-50%);
      transform-origin: top;
      transition: transform 0.15s ease;

      a {
        margin: 0;
        display: inline;
      }

      li {
        padding: ${({ theme }) => `${theme.spacingXXS} ${theme.spacingS}`};
        font-size: 1.2rem;
        font-weight: ${({ theme }) => theme.fontRegular};
        letter-spacing: 0.03rem;
        white-space: nowrap;
      }
    }
  }

  .social {
    a {
      margin: 0 ${({ theme }) => theme.spacingXXXS};

      &.youtube,
      &.linkedin {
        margin-left: ${({ theme }) => theme.spacingXXS};
      }
    }

    svg {
      width: 2rem;
    }
  }

  .hamburguer {
    cursor: pointer;

    span {
      background: ${({ theme }) => theme.blue};
      display: block;
      width: 3.5rem;
      height: 0.35rem;
      margin-bottom: 0.65rem;
    }
  }

  .mobile-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100vh;
    z-index: 111;
    padding: ${({ theme }) => theme.spacingM};
    transform: translateX(101vw);
    transition: transform 0.15s ease;

    &.open {
      transform: translateX(0);
    }

    &__top {
      justify-content: space-between;

      svg {
        &.mobile-logo {
          width: 20rem;
          height: auto;
        }

        &.close-icon {
          height: 4rem;
          width: auto;
        }

        path,
        rect {
          fill: ${({ theme }) => theme.blue};
        }
      }

      span {
        cursor: pointer;
      }
    }

    &__items {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: ${({ theme }) => theme.spacingM};

      .cta {
        margin-bottom: ${({ theme }) => theme.spacingS};
      }

      .nav-item {
        position: relative;
        font-family: ${({ theme }) => theme.fontFamilySecondary};
        font-size: 3rem;
        line-height: 7.7rem;
        font-weight: ${({ theme }) => theme.fontRegular};
        width: 30rem;
        margin: 0 auto;
        padding-top: 5px;
        border-top: 1px solid ${({ theme }) => theme.white};

        &:last-child {
          border-bottom: 1px solid ${({ theme }) => theme.white};
        }

        &:after {
          content: '+';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          font-family: ${({ theme }) => theme.fontFamily};
          font-weight: ${({ theme }) => theme.fontThin};
        }
      }

      ul {
        height: 0;
        overflow: hidden;
        transition: height 0.15s ease;
        list-style-type: none;

        li {
          font-size: 2rem;
          line-height: 3rem;
          padding: ${({ theme }) => theme.spacingXXS};
        }
      }

      .has-dropdown:hover ul,
      .has-dropdown:active ul,
      .has-dropdown:focus ul {
        height: 11rem;
      }
    }

    &__social {
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacingS};

      svg {
        width: 3.5rem;
        margin: 0 ${({ theme }) => theme.spacingXXS};

        path {
          fill: ${({ theme }) => theme.blue};
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .nav__topbar {
      justify-content: center;

      .topbar__item {
        margin: 0 ${({ theme }) => theme.spacingXXS};
      }

      svg {
        width: 2.7rem;
      }

    }

    .nav__main {
      .hamburguer,
      svg {
        transform: translateY(4px);
      }

      .social {
        margin-right: ${({ theme }) => theme.spacingM};

        svg {
          width: 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 460px) {
    .nav__main {
      padding: 0 ${({ theme }) => theme.spacingXS};

      svg {
        width: 13rem;
      }

      .social {
        margin-right: ${({ theme }) => theme.spacingS};
        svg {
          width: 2rem;
        }
      }
    }

    .hamburguer span {
      width: 3rem;
      height: 0.22rem;
    }

    .mobile-nav {
      padding: ${({ theme }) => theme.spacingS};

      svg.mobile-logo {
        width: 15rem;
      }
      svg.close-icon {
        height: 3rem;
      }
    }
  }

  @media screen and (max-width: 330px) {
    .nav__main svg {
      width: 10rem;
    }

    .mobile-nav__items .nav-item {
      width: 100%;
    }
  }
`

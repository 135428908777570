export const parseBlogPosts = (edges) =>
  edges
    .filter((edge) => edge.node.frontmatter.status)
    .map(({ node }) => ({
      id: node.id,
      path: node.frontmatter.path,
      date: node.frontmatter.date,
      title: node.frontmatter.title,
      miniTitle: node.frontmatter.miniTitle,
      img: node.frontmatter.img,
      description: node.frontmatter.description,
      path: node.frontmatter.path,
    }))

export const getDayAndMonth = (date) => {
  const newDate = new Date(date)
  return {
    day: newDate.getDate(),
    month: newDate.getMonth() + 1,
  }
}

export const debounce = (func, wait, immediate) => {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

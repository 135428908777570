import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import favicon from '../assets/favicon.ico'

function SEO({ title, description, image, pathname, article, lang, meta }) {

  const language = 'pt'

  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
              title,
              titleTemplate,
              description,
              siteUrl,
              image
           }
         }
       }
     `
  )

  var titleMeta = title ? title : site.siteMetadata.title
  var descriptionMeta = description ? description : site.siteMetadata.description
  var imageMeta = image ? image : site.siteMetadata.image

  return (

    <Helmet
      title={titleMeta}
      titleTemplate={site.siteMetadata.titleTemplate}
      htmlAttributes={{ lang }}
      meta={[
        {
          name: `description`,
          content: descriptionMeta,
        },
        {
          name: `keywords`,
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: titleMeta,
        },
        {
          property: `og:image`,
          content: imageMeta,
        },
        {
          property: `og:description`,
          content: descriptionMeta,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: titleMeta,
        },
        {
          name: `twitter:description`,
          content: descriptionMeta,
        },
        {
          name: `facebook-domain-verification`,
          content: "db1824ondw6vcnushv5hf0t9huic4n",
        },
      ].concat(meta)}
    >
      <link rel="icon" href={favicon} />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-EQJWCDCLJF"></script>

      <script>
        {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied'})
gtag('set', 'ads_data_redaction', true)
gtag('config', 'G-EQJWCDCLJF');`}
      </script>
      
      <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/1f9d86d80a5966b7aef29b11/script.js"></script>
    

    </Helmet>

  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
}

export default SEO
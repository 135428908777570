import React from 'react'
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import { FloatingCTA } from './FloatingCTA'
import { GlobalStyle } from '../styles/globalstyles'
import { theme } from '../styles/theme'
import styled, { ThemeProvider } from 'styled-components'

const Layout = ({ children, isHomePage, location }) => {


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Navbar isHomePage={isHomePage} location={location}/>
      <StyledLayout>{children}</StyledLayout>
      <FloatingCTA />
      <Footer />
    </ThemeProvider>
  )
}

export default Layout

const StyledLayout = styled.main`
  min-height: calc(100vh - 380px);
`
